
import AddWatchlistButton from '@/components/buttons/AddWatchlistButton'
import Bookmark from '@/components/cards/Bookmark'
import { mdiStar,mdiVideoImage } from '@mdi/js'


export default {
  name: 'MediaCardResponsive',
  data() {
    return {
      svgStar: mdiStar,
      svgVideoImage: mdiVideoImage,
      lazySrc: "data:image/webp;base64,UklGRsoCAABXRUJQVlA4WAoAAAAgAAAAuAAAFQEASUNDUBgCAAAAAAIYAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANlZQOCCMAAAA0A4AnQEquQAWAT5tNplJpCMioSAoAIANiWlu4XaxG0AJ7APfbJyHvtk5D32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ99snIe+sAAD+/7n6AAAAAAAAAAA=",
    }
  },
  props: {
    media: {
      type: Object,
      required: true
    }
  },
  components: {
    AddWatchlistButton,
    Bookmark
  },
  computed: {
    mediaType() {
      if (this.media) {
        return this.media.name ? "tvshows" : "movies"
      }
    },
    mediaTitle() {
      return this.media.name ? this.media.name : this.media.title
    },
    has_poster: function () {
      if (this.media.poster_path != null) { return true; }
      return false;
    },
    mediaReleaseYear() {
      //if its a tv series
      if (this.media.name) {
        return new Date(this.media.first_air_date).toLocaleString('en-US', {
          year: 'numeric',
        })
      }
      //its a movie
      return new Date(this.media.release_date).toLocaleString('en-US', {
        year: 'numeric',
      });

    },
    iconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '13'
        case 'sm': return '14'
        case 'md': return '15'
        case 'lg': return '17'
        case 'xl': return '17'
      }
    }
  }
}
