
/* import { mapState, mapActions } from 'vuex' */
import MediaCardResponsive from "@/components/cards/MediaCardResponsive.vue";
import { mdiChevronRight } from "@mdi/js";

export default {
	components: {
		MediaCardResponsive
	},
	data() {
		return {
			page: 2,
			svgChevronRight: mdiChevronRight,
			loadedExtra: false,
			lcpMoviePoster: "",
			lcpMovieBackdrop: "",
			popularTV: [],
			topTV: [],
			popularMovies: [],
			topMovies: [],
			trendingActors: []
		};
	},
	head() {
		return {
			title: this.seoTitle,
			meta: [
				{ hid: "description", name: "description", content: this.seoDescription },
				{ hid: "og:description", property: "og:description", content: this.seoDescription },
				{ name: "twitter:description", content: this.seoDescription },
				{
					hid: "keywords",
					name: "keywords",
					content: "movies, stream, streaming"
				}
			]
			/* link: [
        { rel: 'preload', href: this.lcpMoviePoster, as: 'image' },
        { rel: 'preload', href: this.lcpMovieBackdrop, as: 'image' },
      ], */
		};
	},
	computed: {
		/* ...mapState(["recentlyViewed"]), */
		seoTitle() {
			if (this.$route.query.keyword) {
				return decodeURIComponent(this.$route.query.keyword);
			} else {
				return "Best Movies 2024 to Watch on Movietube";
			}
		},
		seoDescription() {
			if (this.$route.query.keyword) {
				return decodeURIComponent(this.$route.query.keyword);
			} else {
				return "Watch Popular Movies and Best films for 2024 and TV Series with new episodes today";
			}
		}
	},
	//Fetch data from server - populate banner + LCP images
	async asyncData(context) {
		return context.$axios
			.get(`https://api.themoviedb.org/3/movie/popular?api_key=${process.env.apikey}&language=${process.env.lang}&page=1&include_adult=false`)
			.then(result => {
				//popularMovies in Banner and first row element
				//LCP get the first 1 backdrop + poster and preload them
				return {
					popularMovies: result.data.results
					//lcpMoviePoster: '/_ipx/f_webp,q_80,s_350x522/https://image.tmdb.org/t/p/w500' + result.data.results[0].poster_path,
					//lcpMovieBackdrop: '/_ipx/f_webp,q_80,s_720x480/https://image.tmdb.org/t/p/w1280' + result.data.results[0].backdrop_path,
				};
			})
			.catch(err => {
				console.log(err);
			});
	},
	methods: {
		/* ...mapActions(["emptyRecentlyViewed"]), */
		/*  async getPopularTV() {
       const tv = await this.$axios.$get(`https://api.themoviedb.org/3/tv/popular?api_key=${process.env.apikey}&language=${process.env.lang}&page=1&include_adult=false`)
       this.popularTV = tv.results
     },
     async getTopTV() {
       const tv = await this.$axios.$get(`https://api.themoviedb.org/3/tv/top_rated?api_key=${process.env.apikey}&language=${process.env.lang}&page=1&include_adult=false`)
       this.topTV = tv.results
     },
     async getTopMovies() {
       const movies = await this.$axios.$get(`https://api.themoviedb.org/3/movie/top_rated?api_key=${process.env.apikey}&language=${process.env.lang}&page=1&include_adult=false`)
       this.topMovies = movies.results
     }, */
		async getMoreMovies($state) {
			const movieResult = await this.$axios
				.$get(`https://api.themoviedb.org/3/movie/popular?api_key=${process.env.apikey}&language=${process.env.lang}&include_adult=false&page=${this.page}`)
				.then(movies => {
					if (movies.results.length) {
						this.page += 1;
						this.popularMovies = this.popularMovies.concat(movies.results);
						$state.loaded();
					} else {
						$state.complete();
					}
				})
				.catch(err => {
					// suppress movies lookup error
					// console.log(err)
				});
		}

		/*  handleScroll() {
       if (!this.loadedExtra) {
         this.loadedExtra = true;
         //load extra information on scroll
         this.getPopularTV()
         this.getTopTV()
         this.getTopMovies()
       }

     }, */
	}
	/* created() {
    if (process.client && !this.loadedExtra) {
      window.addEventListener('scroll', this.handleScroll);
    }
  } */
};
