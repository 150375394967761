
export default {
	props: {
		_class: Array,
		tag: {
			type: String,
			default: "div"
		},
		type: {
			type: String,
			default: "display"
		},
		channel: {
			type: String,
			default: "nochannel"
		},
		format: {
			type: String,
			default: "auto"
		},
		adSlot: {
			type: String,
			default: "9486760863"
		}
	},
	head() {
		return {
			script: [{ hid: "adsense", src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4493924743295267", async: true, crossorigin: "anonymous" }]
		};
		return {};
	},
	data() {
		return {
			// use Nuxt RuntimeConfig to enable or disable Adsense
			//isShow: this.$config.ENABLE_ADSENSE
		};
	},
	mounted() {
		this.$nextTick(() => {
			try {
				// this is required for each ad slot (calling this once will only load 1 ad)
				(window.adsbygoogle = window.adsbygoogle || []).push({});
			} catch (error) {
				console.error(error);
			}
		});
	}
};
