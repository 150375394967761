
import { mapState, mapActions } from 'vuex'
import { mdiBookmark,mdiPlus,mdiCheck } from '@mdi/js'

export default {
  name: 'Bookmark',
  data() {
    return {
      svgBookmark: mdiBookmark,
      svgPlus: mdiPlus,
      svgCheck: mdiCheck,
    }
  },
  props: {
    media: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(["watchlist"]),
    inWatchlist() {
      return this.watchlist.some(watchlistMedia => {
        return watchlistMedia.id === this.media.id
      })
    }
  },
  methods: {
    ...mapActions(["addToWatchlist", "removeFromWatchlist"]),
    async addMediaToWatchlist () {
      try {
        await this.addToWatchlist(this.media)
      } catch(err) {
        console.log(err)
      }
    }
  }
}
